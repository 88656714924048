import {ChangeDetectorRef, Component, OnDestroy, OnInit, signal} from '@angular/core';
import {ExplorationService, Obelisk, DisplayMapOptions, GeoBBox, Marker, User} from 'quest-atlas-angular-components';
import {asyncScheduler, debounceTime, Subject, take, takeUntil, throttleTime} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalButton} from '../../common-components/modal/modal.component';
import {TranslocoService} from '@ngneat/transloco';
import {ChooserOption} from '../../common-components/chooser/chooser.component';
import {FormControl} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-exploration',
  templateUrl: './exploration.component.html',
  styleUrls: ['./exploration.component.scss']
})
export class ExplorationComponent implements OnInit, OnDestroy {

  obelisks: Obelisk[] = [];
  showDeleteConfirmation = signal(false);
  obeliskToDelete = signal<Obelisk>(null);
  showApproveConfirmation = signal(false);
  obeliskToApprove = signal<Obelisk>(null);
  xpForApprovedObelisk = new FormControl<number>(null);

  deleteModalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => {
        this.showDeleteConfirmation.set(false);
        this.obeliskToDelete.set(null);
      }
    },
    {
      type: 'error',
      text: this.transloco.translate('delete'),
      onClick: () => this.deleteObelisk(this.obeliskToDelete())
    }
  ];

  approveModalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => {
        this.showApproveConfirmation.set(false);
        this.obeliskToApprove.set(null);
      }
    },
    {
      type: 'primary',
      text: this.transloco.translate('approve'),
      onClick: () => {
        this.approveObelisk();
        this.showApproveConfirmation.set(false);
      }
    }
  ];

  chooserOptions: ChooserOption[];
  viewMode = signal<string>('map');
  user = toSignal<User>(this.userService.getUserInfo$());
  mapOptions: DisplayMapOptions = {
    zoom: 12,
  }
  showOnlyMine = new FormControl<boolean>(true);

  loadObelisksForMap$$ = new Subject<mapboxgl.Map>();
  loadMarkersForMap$$ = new Subject<mapboxgl.Map>();

  searchBar = new FormControl<string>('');
  loadingDataFromSearchValue?: string;

  private latestMap: mapboxgl.Map;

  private destroy$ = new Subject<void>();

  constructor(
    private explorationService: ExplorationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transloco: TranslocoService,
    private cdRef: ChangeDetectorRef,
    private userService: UserService
  ) {
  }

  ngOnInit() {
    this.loadObelisks();

    this.chooserOptions = [
      {
        name: this.transloco.translate('mapView'),
        chosen: true,
        value: 'map'
      },
      {
        name: this.transloco.translate('tableView'),
        chosen: false,
        value: 'table'
      }
    ];

    this.loadObelisksForMap$$.pipe(throttleTime(500, asyncScheduler, {leading: true, trailing: true}))
      .subscribe((map) => {
        this.latestMap = map;
        this.loadObelisksForMap(map)
        this.loadMarkersForMap(map);
      });

    this.showOnlyMine.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadObelisks();
      if (this.latestMap) {
        this.loadObelisksForMap(this.latestMap);
      }
    });

    this.searchBar.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((search) => {
      this.loadObelisks(search);
    });
  }

  loadObelisks(search = '') {
    if (search && search === this.loadingDataFromSearchValue) {
      return;
    }

    this.loadingDataFromSearchValue = search;

    this.explorationService.loadObelisks$([], null, search, {adminMode: this.user()?.isSuperUser && !this.showOnlyMine.value})
      .pipe(takeUntil(this.destroy$)).subscribe((obelisks) => {
        this.obelisks = obelisks;
        this.obelisks.sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1);

        this.cdRef.detectChanges();
    });
  }

  goToEditObelisk(obelisk: Obelisk) {
    this.router.navigate(['obelisks-editor', obelisk.uuid]);
  }

  goToCreateObeliskFromMarker(marker: Marker) {
    this.router.navigate(['obelisks-editor', 'new'], {queryParams: {markerId: marker.uuid}});
  }

  handleDeleteObelisk(obelisk: Obelisk) {
    this.obeliskToDelete.set(obelisk);
    this.showDeleteConfirmation.set(true);
  }

  deleteObelisk(obelisk: Obelisk) {
    this.explorationService.deleteObelisk$(obelisk).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadObelisks();
      this.showDeleteConfirmation.set(false);
      this.obeliskToDelete.set(null);
    });
  }

  viewChange(option: ChooserOption) {
    this.chooserOptions.forEach(o => o.chosen = false);
    option.chosen = true;

    this.viewMode.set(option.value);
  }

  onMapMoveEnd(map: mapboxgl.Map) {
    this.loadObelisksForMap$$.next(map);
    this.loadMarkersForMap$$.next(map);
  }

  onMapZoom(map: mapboxgl.Map) {
    this.loadObelisksForMap$$.next(map);
    this.loadMarkersForMap$$.next(map);
  }

  loadObelisksForMap(map: mapboxgl.Map) {
    const bbox: GeoBBox = {
      sw: map.getBounds().getSouthWest(),
      ne: map.getBounds().getNorthEast()
    }

    this.explorationService.loadObelisks$([], bbox, null, {adminMode: this.user()?.isSuperUser && !this.showOnlyMine.value})
      .pipe(takeUntil(this.destroy$)).subscribe((obelisks) => {
      this.mapOptions = {
        ...this.mapOptions,
        zoom: undefined,
        obelisks
      };

      this.cdRef.detectChanges();
    });
  }

  loadMarkersForMap(map: mapboxgl.Map) {
    const sw = map.getBounds().getSouthWest();
    const ne = map.getBounds().getNorthEast();

    this.explorationService.loadMarkers$({sw, ne}, '').pipe(
      take(1),
      takeUntil(this.destroy$)
    ).subscribe((markers) => {
      this.mapOptions = {
        ...this.mapOptions,
        zoom: undefined,
        markers
      };

      this.cdRef.detectChanges();
    });
  }

  approveObelisk() {
    this.explorationService.approveObelisk$(this.obeliskToApprove(), this.xpForApprovedObelisk.value).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.obeliskToApprove().approved = true;
      this.cdRef.detectChanges();
    });
  }

  rejectObelisk(obelisk: Obelisk) {
    this.explorationService.rejectObelisk$(obelisk).pipe(takeUntil(this.destroy$)).subscribe(() => {
      obelisk.approved = false;
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.loadObelisksForMap$$.complete();
    this.loadMarkersForMap$$.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }
}
