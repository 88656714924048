<mat-toolbar class="toolbar top">
  <span class="main-heading-text">{{(isNew ? 'createNew' : 'edit') | transloco}} {{'obelisk' | transloco}}</span>
  <img class="pointer" src="assets/project-icons/x.svg" alt="X" routerLink="/exploration" />
</mat-toolbar>

<div class="map-editor-container">
  @if (obeliskForm) {
    <form class="controls" [formGroup]="obeliskForm" *transloco="let t">
      <div class="controls-row">
        <button
          class="secondary-bar-btn add-buttons"
          (click)="editingObeliskCenter = !editingObeliskCenter; editingObeliskZone = false; stopEditCurrentPathway()"
          [ngClass]="{ active: editingObeliskCenter }"
          [disabled]="!!obeliskForm.get('location').value"
          >
          <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
            <use xlink:href="assets/project-icons/add.svg#a"></use>
          </svg>
          {{t('addObeliskCenter')}}
        </button>
      </div>
      <div class="chips-container">
        @if (!!obeliskForm.get('location').value) {
          <app-active-chip
            [text]="t('obeliskCenter')"
            [selected]="editingObeliskCenter"
            [color]="'default'"
            (click)="clickObeliskBtn(); stopEditCurrentPathway()"
            (close)="removeObeliskMarker(); editingObeliskCenter = false"
            >
          </app-active-chip>
        }
      </div>
      @if (obeliskForm.get('location').value) {
        <div class="controls-row" style="justify-content: center">
          <app-chooser [options]="zoneTypeOptions" (optionChosen)="choseZoneType($event)">
          </app-chooser>
        </div>
        @if (obeliskForm.get('zoneType').value === 'circle') {
          <div class="controls-row margin-top-18">
            <input type="range" min="4" max="100" formControlName="zoneRadius" class="general-range" />
            <span class="range-label">
              {{ metersToPretty(obeliskForm.get('zoneRadius').value) }}
            </span>
          </div>
        }
      }
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field margin-top-18 margin-bottom-18" color="accent">
          <mat-label>{{t('name')}}</mat-label>
          <input matInput formControlName="name" minlength="1" maxlength="100"/>
        </mat-form-field>
      </div>
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field margin-bottom-18" color="accent">
          <mat-label>{{t('subTitle')}}</mat-label>
          <input matInput formControlName="subTitle" maxlength="1000"/>
        </mat-form-field>
      </div>
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field mat-text-area margin-bottom-24" color="accent">
          <mat-label>{{t('description')}}</mat-label>
          <textarea matInput formControlName="description" rows="4" maxlength="3000"></textarea>
        </mat-form-field>
      </div>
      <div class="controls-row margin-bottom-18">
        <app-dropdown
          class="full-width-container"
          [title]="t('category')"
          [options]="categoryOptions"
          formControlName="category"
        ></app-dropdown>
      </div>
      <div class="controls-row margin-bottom-18">
        <app-dropdown
          class="full-width-container"
          [title]="t('subcategory')"
          [options]="subCategoriesOptions[obeliskForm.value?.category?.value] || []"
          formControlName="subCategory"
        ></app-dropdown>
      </div>
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field margin-bottom-18" color="accent">
          <mat-label>{{t('address')}}</mat-label>
          <input matInput formControlName="address" minlength="1"/>
        </mat-form-field>
      </div>
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field margin-bottom-18" color="accent">
          <mat-label>{{t('website')}}</mat-label>
          <input matInput formControlName="website" minlength="1"/>
        </mat-form-field>
      </div>
      <div class="controls-row">
        <mat-form-field appearance="fill" class="form-field mat-text-area margin-bottom-24" color="accent">
          <mat-label>{{t('howToGetToThePlace')}}</mat-label>
          <textarea matInput formControlName="howToGet" rows="4" maxlength="3000"></textarea>
        </mat-form-field>
      </div>
      <app-collapsable-section [title]="t('pathways') + ' (' + getPathwaysAsFormGroups().length + ')'">
        <div class="pathways-section">
          <button class="secondary-bar-btn add-buttons"
                  (click)="addPathway()">
            <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
              <use xlink:href="assets/project-icons/add.svg#a"></use>
            </svg>
            {{t('addPathway')}}
          </button>

          @for (pathway of getPathwaysAsFormGroups(); track pathway.value.uuid) {
            <div class="pathway-controls" [formGroup]="pathway">
              <mat-form-field appearance="fill" class="form-field" color="accent">
                <mat-label>{{t('pathwayName')}}</mat-label>
                <input matInput formControlName="name" />
              </mat-form-field>
              <div class="bottom-controls">
                <input
                  class="color-selector-button"
                  [style.background]="pathway.value.color"
                  [cpPresetColors]="['#2bce07', '#f8d00c', '#2889e9', '#f10303']"
                  [(colorPicker)]="pathway.value.color"
                  (colorPickerChange)="pathway.patchValue({color: $event})"
                />
                <div class="map-to-roads-controls-container">
                  <button class="secondary-bar-btn"
                          [ngClass]="{active: pathway.value.mappedToRoads}"
                          (click)="mapToRoadsToggle(pathway)"
                          [disabled]="currentPathway?.value.uuid !== pathway.value.uuid || (pathwaysEditEngines.get(pathway.value.uuid)?.orderedVertices?.length || 0) < 3">
                    {{t('mapToRoads')}}
                  </button>
                  :

                  <button class="image-btn borderless"
                          [ngClass]="{active: pathway.value.mappedPathProfile === 'walking'}"
                          (click)="changePathwayMappingProfile(pathway, 'walking')"
                          [disabled]="!pathway.value.mappedToRoads">
                    <q-svg-wrapper iconPath="project-icons/walking-32" width="24" height="24" viewBox="0 0 32 32"></q-svg-wrapper>
                  </button>
                  <button class="image-btn borderless"
                          [ngClass]="{active: pathway.value.mappedPathProfile === 'cycling'}"
                          (click)="changePathwayMappingProfile(pathway, 'cycling')"
                          [disabled]="!pathway.value.mappedToRoads">
                    <q-svg-wrapper iconPath="project-icons/cycling-32" width="24" height="24" viewBox="0 0 32 32"></q-svg-wrapper>
                  </button>
                  <button class="image-btn borderless"
                          [ngClass]="{active: pathway.value.mappedPathProfile === 'driving'}"
                          (click)="changePathwayMappingProfile(pathway, 'driving')"
                          [disabled]="!pathway.value.mappedToRoads">
                    <q-svg-wrapper iconPath="project-icons/car-32" width="24" height="24" viewBox="0 0 32 32"></q-svg-wrapper>
                  </button>
                </div>
                <div class="display-flex-centered" style="gap: 4px">
                  <div class="image-btn">
                    <q-svg-wrapper iconPath="project-icons/x" (click)="removePathway(pathway)"></q-svg-wrapper>
                  </div>
                  <div class="image-btn" [ngClass]="{active: currentPathway?.value.uuid === pathway.value.uuid}">
                    <q-svg-wrapper iconPath="project-icons/pencil" (click)="startEditPathway(pathway)"></q-svg-wrapper>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </app-collapsable-section>
      <app-collapsable-section [title]="t('comments') + ' (' + getCommentsAsFormGroups().length + ')'">
        <div class="comments-section">
          <button class="secondary-bar-btn add-buttons"
                  (click)="addComment()">
            <svg class="smaller-img-size" viewBox="0 0 24 24" width="18" height="18">
              <use xlink:href="assets/project-icons/add.svg#a"></use>
            </svg>
            {{t('addComment')}}
          </button>
          @if (commentsShown) {
            <button class="warning-bar-btn add-buttons" (click)="hideComments()">
              {{t('hideComments')}}
            </button>
          } @else {
            <button class="primary-bar-btn add-buttons" (click)="showComments()">
              {{t('showComments')}}
            </button>
          }

          @for (comment of getCommentsAsFormGroups(); track comment.value.uuid) {
            <lib-comment-section-editor
              [commentFormGroup]="comment"
              [editEngine]="commentsEditEngines.get(comment.value.uuid)"
              (deleteSelf)="removeComment(comment);"
            ></lib-comment-section-editor>
          }
        </div>
      </app-collapsable-section>
      <div class="controls-row margin-top-16">
        <app-dropdown
          class="full-width-container margin-bottom-18"
          [title]="t('importance')"
          [options]="importanceOptions"
          formControlName="importance"
        ></app-dropdown>
      </div>

      <div class="form-row" style="justify-content: left">
            <span
              class="usual-flex-center general-big-text link-text"
              [ngClass]="{ disabled: getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES }"
              (click)="addImageClick()"
            >
              <svg class="regular-img-size">
                <use xlink:href="assets/project-icons/add.svg#a"></use>
              </svg>
              {{t('addPhoto')}}
            </span>
        <span
          class="usual-flex-center general-big-text link-text paste-photo-btn"
          [ngClass]="{ disabled: getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES }"
          (click)="clickPasteImage()"
        >
              <svg class="regular-img-size">
                <use xlink:href="assets/project-icons/clipboard.svg#c"></use>
              </svg>
          {{t('pastePhoto')}}
            </span>
      </div>

      <div class="photos-row">
        @for (image of getCurrentImages(); track image; let i = $index) {
          <div class="img-thumbnail">
            <img [src]="image.uri" alt="" />
            <div class="icon-container edit">
              <q-svg-wrapper class="small-icon pointer" [ngClass]="{'q-green': image.description}" iconPath="project-icons/pencil" width="14" height="14" viewBox="0 0 24 24" (click)="openEditImageDescriptionDialog(image)"></q-svg-wrapper>
            </div>
            <div class="icon-container close">
              <q-svg-wrapper class="small-icon pointer" iconPath="project-icons/close" width="16" height="16" viewBox="0 0 24 24" (click)="removeImgByUuid(image.uuid)"></q-svg-wrapper>
            </div>
          </div>
        }
      </div>
    </form>
  }

  <div id="map-container" class="map">
    <!--    <div class="map-controls" *ngIf="(mapControlVisibility$ | async)?.state === 'show'">-->
    <!--      <div class="map-control-slot pointer" *ngIf="(mapControlVisibility$ | async)?.showConfirm" (click)="mapControlPanelActions$.next({ button: 'confirm' })">-->
    <!--        <svg class="regular-img-size color-green">-->
    <!--          <use xlink:href="assets/project-icons/check.svg#c"></use>-->
  <!--        </svg>-->
<!--      </div>-->
<!--    </div>-->

<button class="map-btn" style="top: 156px" (click)="goToMyLocation()">
  <svg class="pointer" viewBox="0 0 24 24" width="20" height="20">
    <use xlink:href="assets/map-icons/location.svg#c"></use>
  </svg>
</button>

<button class="map-btn" style="top: 200px" (click)="toggleMagStyle()">
  <svg class="pointer" viewBox="0 0 24 24" width="20" height="20">
    <use xlink:href="assets/map-icons/layers.svg#c"></use>
  </svg>
</button>
</div>
</div>

<mat-toolbar class="toolbar content-end bottom element-shadow" *transloco="let t">
  @if (isNew) {
    @if (fromMarker) {
      <button class="warning-bar-btn" (click)="deleteMarker()" [disabled]="saving">
        <svg class="regular-img-size">
          <use xlink:href="assets/project-icons/close.svg#c"></use>
        </svg>
        {{t('delete')}} {{t('marker')}}
      </button>
    }
    <button class="primary-bar-btn" (click)="createObelisk()" [disabled]="!isValid() || saving">
      <svg class="regular-img-size">
        <use xlink:href="assets/project-icons/check.svg#c"></use>
      </svg>
      {{t('create')}}
    </button>
  } @else {
    <button class="warning-bar-btn" (click)="deleteObelisk()" [disabled]="saving">
      <svg class="regular-img-size">
        <use xlink:href="assets/project-icons/trash.svg#c"></use>
      </svg>
      {{t('delete')}}
    </button>
    <button class="primary-bar-btn" (click)="updateObelisk()" [disabled]="!isValid() || saving">
      <svg class="regular-img-size">
        <use xlink:href="assets/project-icons/check.svg#c"></use>
      </svg>
      {{t('update')}}
    </button>
  }
</mat-toolbar>

@if (editingImageDescriptionId) {
  <q-modal [title]="'editImageDescription' | transloco" [size]="'medium'" [footerButtons]="editDescriptionModalButtons" (closeEvent)="closeEditImageDescriptionDialog()">
    <mat-form-field appearance="fill" class="form-field mat-text-area" color="accent">
      <mat-label>{{'description' | transloco}}</mat-label>
      <textarea matInput [(ngModel)]="editingImageDescription" rows="5" maxlength="500"></textarea>
    </mat-form-field>
  </q-modal>
}

<input id="add-photo-input" style="display: none" type="file" accept="image/png, image/jpeg" />
