import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {asyncScheduler, BehaviorSubject, filter, finalize, observeOn, of, Subject, take, takeUntil} from 'rxjs';
import {
  isObeliskZoneCircle,
  Marker,
  Obelisk,
  ObeliskImage,
  ObeliskZoneCircle,
  RelativeCoords,
  ObeliskComment,
  Dict,
  zoneToFeatureLineString
} from 'quest-atlas-angular-components';
import {ObeliskType} from 'quest-atlas-angular-components';
import * as mapboxgl from 'mapbox-gl';
import {GeoCoords} from 'quest-atlas-angular-components';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {
  addLayersToMap,
  coordsToQGraph,
  drawMarkerOnMap,
  PathwayEditEngine,
  MapboxSpecificService,
  CommentsEditEngine,
  ModalButton
} from 'quest-atlas-angular-components';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {ChooserOption} from '../../common-components/chooser/chooser.component';
import * as turf from '@turf/turf';
import {metersToPretty} from 'quest-atlas-angular-components';
import {ObeliskZoneEditEngine} from './obelisk-zone-edit-engine.class';
import {QuestDropdownValues} from '../../common-components/dropdown/dropdown.component';
import {ExplorationService, Pathway} from 'quest-atlas-angular-components';
import {ActivatedRoute, Router} from '@angular/router';
import {EXPLORATION_PATH} from '../../app-routing.module';
import {readImageFromClipboard} from '../../functions/file.functions';
import {v4 as uuidv4} from 'uuid';
import {TranslocoService} from '@ngneat/transloco';
import {switchMap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-obelisks-editor',
  templateUrl: './obelisks-editor.component.html',
  styleUrls: ['./obelisks-editor.component.scss']
})
export class ObelisksEditorComponent implements OnInit, AfterContentInit, OnDestroy {
  readonly MAX_NUMBER_OF_IMAGES = 11;

  obeliskForm: FormGroup;

  obeliskMarker: mapboxgl.Marker;

  map!: mapboxgl.Map;
  mapLoaded = false;

  editingObeliskCenter = false;
  editingObeliskZone = false;

  userGeo?: GeoCoords;
  isNew = true;

  obelisk$ = new BehaviorSubject<Obelisk>(null);
  currentPathway?: FormGroup;
  pathwaysEditEngines = new Map<string, PathwayEditEngine>();
  pathwaysStrokeColors = new Map<string, BehaviorSubject<string>>

  currentComment?: FormGroup;
  commentsEditEngines = new Map<string, CommentsEditEngine>();
  commentsShown = true;
  /**
   * @deprecated
   */
  inMarkerMode = false;
  fromMarker: Marker;

  editingImageDescriptionId?: string;
  editingImageDescription = '';
  editDescriptionModalButtons: ModalButton[] = [
    {
      type: 'secondary',
      text: this.transloco.translate('cancel'),
      onClick: () => {
        this.closeEditImageDescriptionDialog();
      }
    },
    {
      type: 'primary',
      text: this.transloco.translate('save'),
      onClick: () => {
        this.submitEditImageDescription();
      }
    }
  ]

  zoneTypeOptions: ChooserOption[] = [
    {name: this.transloco.translate('circle'), value: 'circle', chosen: true},
    {name: this.transloco.translate('polygon'), value: 'polygon'}
  ];

  importanceOptions: QuestDropdownValues[] = [
    {title: this.transloco.translate('small'), value: 'small'},
    {title: this.transloco.translate('medium'), value: 'medium'},
    {title: this.transloco.translate('big'), value: 'big'},
    {title: this.transloco.translate('national'), value: 'national'},
    {title: this.transloco.translate('world'), value: 'world'},
  ];

  categoryOptions: QuestDropdownValues[] = [
    {title: this.transloco.translate('natureObjects'), value: 'natureObjects'},
    {title: this.transloco.translate('culturalAttractions'), value: 'culturalAttractions'},
    {title: this.transloco.translate('activeRecreation'), value: 'activeRecreation'},
    {title: this.transloco.translate('entertainmentAndLeisure'), value: 'entertainmentAndLeisure'},
    {title: this.transloco.translate('gastronomicTourism'), value: 'gastronomicTourism'},
  ];

  subCategoriesOptions: Dict<QuestDropdownValues[]> = {
    natureObjects: [
      {title: this.transloco.translate('waterfall'), value: 'waterfall'},
      {title: this.transloco.translate('river'), value: 'river'},
      {title: this.transloco.translate('lake'), value: 'lake'},
      {title: this.transloco.translate('mountain'), value: 'mountain'},
      {title: this.transloco.translate('cave'), value: 'cave'},
      {title: this.transloco.translate('lawn'), value: 'lawn'},
      {title: this.transloco.translate('spring'), value: 'spring'},
      {title: this.transloco.translate('other'), value: 'other'},
    ],
    culturalAttractions: [
      {title: this.transloco.translate('palace'), value: 'palace'},
      {title: this.transloco.translate('castle'), value: 'castle'},
      {title: this.transloco.translate('fortress'), value: 'fortress'},
      {title: this.transloco.translate('museum'), value: 'museum'},
      {title: this.transloco.translate('gallery'), value: 'gallery'},
      {title: this.transloco.translate('theatre'), value: 'theatre'},
      {title: this.transloco.translate('church'), value: 'church'},
      {title: this.transloco.translate('monument'), value: 'monument'},
      {title: this.transloco.translate('other'), value: 'other'},
    ],
    activeRecreation: [
      {title: this.transloco.translate('camping'), value: 'camping'},
      {title: this.transloco.translate('picnic'), value: 'picnic'},
      {title: this.transloco.translate('fishing'), value: 'fishing'},
      {title: this.transloco.translate('hikingTrail'), value: 'hikingTrail'},
      {title: this.transloco.translate('runningTrail'), value: 'runningTrail'},
      {title: this.transloco.translate('bikingTrail'), value: 'bikingTrail'},
      {title: this.transloco.translate('other'), value: 'other'},
    ],
    entertainmentAndLeisure: [
      {title: this.transloco.translate('amusementPark'), value: 'amusementPark'},
      {title: this.transloco.translate('waterPark'), value: 'waterPark'},
      {title: this.transloco.translate('zoo'), value: 'zoo'},
      {title: this.transloco.translate('restArea'), value: 'restArea'},
      {title: this.transloco.translate('other'), value: 'other'},
    ],
    gastronomicTourism: [
      {title: this.transloco.translate('restaurant'), value: 'restaurant'},
      {title: this.transloco.translate('cafe'), value: 'cafe'},
      {title: this.transloco.translate('bar'), value: 'bar'},
      {title: this.transloco.translate('pub'), value: 'pub'},
      {title: this.transloco.translate('fastFood'), value: 'fastFood'},
      {title: this.transloco.translate('localFarm'), value: 'localFarm'},
      {title: this.transloco.translate('winery'), value: 'winery'},
      {title: this.transloco.translate('foodMarket'), value: 'foodMarket'},
      {title: this.transloco.translate('other'), value: 'other'},
    ]
  }

  polygonZoneEditEngine: ObeliskZoneEditEngine;

  saving = false;

  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private explorationService: ExplorationService,
              private mapboxSpecificService: MapboxSpecificService,
              private transloco: TranslocoService,
              private snackBar: MatSnackBar,
              private router: Router) {
  }

  ngOnInit(): void {
    // TODO Додати категорії ще до таблички обелісків
    // TODO implement caching like in quest editor or chains editor
    navigator.geolocation.getCurrentPosition((value) => {
      this.userGeo = {lng: value.coords.longitude, lat: value.coords.latitude};
    });

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params['markerId']) {
        this.isNew = true;

        this.explorationService.getMarkerById$(params['markerId']).pipe(takeUntil(this.destroy$)).subscribe((marker) => {
          this.fromMarker = marker;
          this.obelisk$.next({
            name: marker.name,
            type: ObeliskType.stationary,
            importance: 'medium',
            location: marker.location,
            description: marker.description,
            address: marker.address,
            images: marker.images.map((image) => {
              return {
                uuid: image.uuid,
                uri: image.uri,
                saved: true,
                order: image.order,
              }
            }),
            zone: {
              type: 'circle',
              radius: 10,
            }
          });

          this.obeliskMarker = this.drawObeliskMarker(marker.location);
          this.map.setCenter(marker.location);
        });
      }
    });

    this.activatedRoute.params.pipe(observeOn(asyncScheduler)).subscribe((value) => {
      if (value['id'] === 'new') {
        this.isNew = true;
        this.obelisk$.next({
          name: '',
          type: ObeliskType.stationary,
          importance: 'medium',
          zone: {
            type: 'circle',
            radius: 10,
          }
        });
      } else {
        this.isNew = false;
        if (!this.fromMarker) {
          this.explorationService.getObeliskById$(value['id']).pipe(takeUntil(this.destroy$)).subscribe((obelisk) => {
            this.obelisk$.next(obelisk);
            this.obeliskMarker = this.drawObeliskMarker(obelisk.location);
            this.map.setCenter(obelisk.location);
          });
        }
      }
    });

    this.obelisk$.pipe(filter(value => !!value), take(2), takeUntil(this.destroy$)).subscribe(obelisk => {
      const existingPathways = (obelisk.config?.['pathways'] || []) as Array<Pathway>;
      const existingComments = (obelisk.config?.['comments'] || []) as Array<ObeliskComment>;

      this.obeliskForm = this.fb.group({
        name: [obelisk.name, [Validators.required]],
        subTitle: [obelisk.subTitle, []],
        description: [obelisk.description, []],
        svg: [obelisk.svg],
        type: [obelisk.type, [Validators.required]],
        category: [this.categoryOptions.find(i => i.value === obelisk.category), []],
        subCategory: [this.subCategoriesOptions[obelisk.category]?.find(i => i.value === obelisk.subCategory), []],
        importance: [this.importanceOptions.find(i => i.value === obelisk.importance), [Validators.required]],
        address: [obelisk.address, [Validators.required]],
        howToGet: [obelisk.config?.['howToGet'], []],
        website: [obelisk.config?.['website'], []],
        location: [obelisk.location, [Validators.required]],
        zoneType: [obelisk.zone.type, [Validators.required]],
        zoneRadius: [isObeliskZoneCircle(obelisk.zone) ? obelisk.zone.radius : 10, []],
        polygonVertices: [isObeliskZoneCircle(obelisk.zone) ? [] : obelisk.zone.vertices, []],
        images: [obelisk.images || [], []],
        pathways: this.fb.array(existingPathways.map((pathway) => this.createPathwayFormGroup(pathway))),
        comments: this.fb.array(existingComments.map((comment) => this.createCommentFormGroup(comment)))
      });

      for (const pathway of (this.obeliskForm.get('pathways') as FormArray).controls) {
        this.initPathway(pathway as FormGroup);
      }

      for (const comment of (this.obeliskForm.get('comments') as FormArray).controls) {
        this.initComment(comment as FormGroup);
      }

      if (isObeliskZoneCircle(obelisk.zone)) {
        this.zoneTypeOptions[0].chosen = true;
        this.zoneTypeOptions[1].chosen = false;
        // just to give time for the map to load
        if (!this.isNew) {
          setTimeout(() => {
            this.onRadiusChange((obelisk.zone as ObeliskZoneCircle).radius);
          }, 1000);
        }
      } else {
        this.zoneTypeOptions[0].chosen = false;
        this.zoneTypeOptions[1].chosen = true;
      }

      this.obeliskForm.get('zoneRadius')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.onRadiusChange(value);
      });

      this.obeliskForm.get('category')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this.obeliskForm.patchValue({
          subCategory: null
        })
      });

      this.polygonZoneEditEngine = new ObeliskZoneEditEngine(this.map, isObeliskZoneCircle(obelisk.zone) ? null : obelisk.zone.absolutePolygon, () => {
      });
      this.polygonZoneEditEngine.onZoneConfirmed$().subscribe((zone) => {
        const polygonZone: RelativeCoords[] = zone.connections.map((connection) => {
          const v = zone.vertices[connection.startVertexId];

          return {
            lngDiff: this.obeliskForm.value.location.lng - v.coords.lng,
            latDiff: this.obeliskForm.value.location.lat - v.coords.lat
          }
        });

        this.obeliskForm.patchValue({
          polygonVertices: polygonZone
        });
      });
    });

    // @ts-ignore
    mapboxgl.accessToken = environment.mapboxPublicToke;
    let centerAndZoom = {
      zoom: 2,
      center: [0, 0]
    };

    const lastLocation = this.getLastLocation();

    if (lastLocation) {
      centerAndZoom = {
        zoom: 14,
        center: [lastLocation.lng, lastLocation.lat]
      };
    } else if (this.userGeo) {
      centerAndZoom = {
        zoom: 13,
        center: [this.userGeo.lng, this.userGeo.lat]
      };
    }

    // @ts-ignore
    this.map = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/mapbox/outdoors-v12',
      ...centerAndZoom
    });

    // Add map controls
    this.map.addControl(
      new MapboxGeocoder({
        accessToken: environment.mapboxPublicToke,
        mapboxgl: mapboxgl
      })
    );

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.setLanguage([navigator.language, 'en']);
    this.map.on('load', () => {
      this.mapLoaded = true;

      addLayersToMap(this.map);

      if (this.obeliskMarker && this.obeliskForm.value.zoneType === 'circle') {
        this.onRadiusChange(this.obeliskForm.value.zoneRadius);
      }
    });

    this.map.on('click', (ev: any) => {
      if (this.currentPathway) {
        this.pathwaysEditEngines.get(this.currentPathway.value.uuid).clickOnMap(ev.lngLat);
        return;
      }

      if (this.currentComment) {
        this.commentsEditEngines.get(this.currentComment.value.uuid).clickOnMap(ev.lngLat);
        return;
      }

      if (this.editingObeliskCenter) {
        this.storeLastLocation(ev.lngLat);

        if (this.obeliskMarker) {
          this.removeObeliskMarker();
        }

        this.obeliskMarker = this.drawObeliskMarker(ev.lngLat);

        if (this.obeliskForm.value.zoneType === 'circle') {
          this.changeRadius(this.obeliskForm.value.zoneRadius);
        } else if (this.obeliskForm.value.zoneType === 'polygon') {
          this.polygonZoneEditEngine.setZone(coordsToQGraph(
            this.obeliskForm.value.polygonVertices.map((v: RelativeCoords) => {
              return {
                lng: ev.lngLat.lng - v.lngDiff,
                lat: ev.lngLat.lat - v.latDiff
              }
            })
          ));
        }

        this.editingObeliskCenter = false;
      } else if (this.obeliskForm.value.zoneType === 'polygon') {
        this.addPolygonVertex(ev.lngLat);
      }
    });

    this.map.on('mousemove', (ev: any) => {
      if (this.currentPathway) {
        this.pathwaysEditEngines.get(this.currentPathway.value.uuid).hoverOnMap(ev.lngLat);
      }
    });
  }

  ngAfterContentInit(): void {
    const addPhotoInput = document.getElementById('add-photo-input');
    addPhotoInput!.addEventListener('change', (event: any) => {
      const files = event?.target?.files;
      if (!files || files.length === 0) {
        console.error('No files uploaded');
        return;
      }

      this.readImage(files[0]);
    });
  }

  createObelisk(): void {
    this.saving = true;

    const obelisk: Obelisk = this.obeliskFromCurrentForm();

    this.explorationService.createObelisk$(obelisk)
      .pipe(
        switchMap((value) => {
          if (this.fromMarker) {
            return this.explorationService.deleteMarker$(this.fromMarker.uuid);
          }

          return of(void 0)
        }),
        finalize(() => this.saving = false)
      ).subscribe(() => {
      this.router.navigate([EXPLORATION_PATH]);
    });
  }

  updateObelisk(): void {
    this.saving = true;
    this.obelisk$.pipe(take(1)).subscribe(value => {
      const obelisk: Obelisk = this.obeliskFromCurrentForm();
      obelisk.uuid = value.uuid;

      this.explorationService.updateObelisk$(obelisk).subscribe(() => {
        this.saving = false;
        this.router.navigate([EXPLORATION_PATH]);
      });
    });
  }

  deleteObelisk(): void {
    this.saving = true;
    this.obelisk$.pipe(take(1)).subscribe(value => {
      this.explorationService.deleteObelisk$(value).subscribe(() => {
        this.saving = false;
        this.router.navigate([EXPLORATION_PATH]);
      });
    });
  }

  deleteMarker(): void {
    this.saving = true;
    this.obelisk$.pipe(take(1)).subscribe(value => {
      this.explorationService.deleteMarker$(this.fromMarker.uuid).subscribe(() => {
        this.saving = false;
        this.router.navigate([EXPLORATION_PATH]);
      });
    });
  }


  addPathway(): void {
    this.stopEditCurrentPathway();

    const pathway = this.createPathwayFormGroup({
      uuid: uuidv4(),
      name: '',
      color: '#ffa822',
      vertices: [],
      mappedPathProfile: 'walking',
    });
    this.currentPathway = pathway;
    this.getPathways().push(pathway);

    const pE = this.initPathway(pathway);

    setTimeout(() => {
      pathway.markAsTouched();
      pathway.markAsDirty();
      pathway.markAllAsTouched();
    }, 100);

    this.map.once('styledata', () => {
      pE.startEditing();
    });
  }

  initPathway(pathway: FormGroup): PathwayEditEngine {
    this.pathwaysStrokeColors.set(pathway.value.uuid, new BehaviorSubject<string>(pathway.value.color));

    pathway.valueChanges.subscribe((value) => {
      this.pathwaysStrokeColors.get(pathway.value.uuid).next(value.color);
    });

    const pE = new PathwayEditEngine(
      this.map,
      of({button: 'confirm'}),
      'pathway-' + pathway.value.uuid,
      this.pathwaysStrokeColors.get(pathway.value.uuid).asObservable(),
      pathway.value.path,
      () => {

      });

    this.pathwaysStrokeColors.get(pathway.value.uuid).subscribe((value) => {

    });

    pE.onEdit$().subscribe(() => {
      const data = pE.getData();
      pathway.patchValue({
        path: data.cleanZone,
        vertices: data.vertices,
      });

      if (pathway.value.mappedToRoads) {
        this.loadMapMatching(pathway);
      }
    });

    pE.stopEdit$().subscribe(() => {
      this.currentPathway = undefined;
      pE.hideMarkers();
    });

    this.pathwaysEditEngines.set(
      pathway.value.uuid,
      pE
    );

    if (pathway.value.mappedToRoads) {
      this.map.once('styledata', () => {
        this.updateMappedPathwaysLayerData(pathway, pathway.value.mapMappedPath);
      });
    }

    this.addPathwayMapLayers(pathway);

    return pE;
  }

  stopEditCurrentPathway(): void {
    if (this.currentPathway) {
      this.pathwaysEditEngines.get(this.currentPathway.value.uuid).hideMarkers();
      this.pathwaysEditEngines.get(this.currentPathway.value.uuid).stopEditing();
      this.currentPathway = undefined;
    }
  }

  startEditPathway(pathway: FormGroup): void {
    if (this.currentPathway === pathway) {
      this.stopEditCurrentPathway()
      return;
    }

    if (this.currentPathway) {
      this.stopEditCurrentPathway();
    }

    this.currentPathway = pathway;
    this.pathwaysEditEngines.get(pathway.value.uuid).startEditing();
    this.pathwaysEditEngines.get(pathway.value.uuid).revealMarkers();
  }

  removePathway(pathway: FormGroup): void {
    this.stopEditCurrentPathway();
    this.pathwaysEditEngines.get(pathway.value.uuid).destroy();
    this.pathwaysEditEngines.delete(pathway.value.uuid);

    this.pathwaysStrokeColors.get(pathway.value.uuid).complete();
    this.pathwaysStrokeColors.delete(pathway.value.uuid);

    this.getPathways().removeAt(this.getPathways().controls.indexOf(pathway));

    //   remove map sources
    this.map.removeLayer('pathway-' + pathway.value.uuid);
    this.map.removeLayer('pathway-' + pathway.value.uuid + '-outline');
    this.map.removeSource('pathway-' + pathway.value.uuid);

    this.map.removeLayer('pathway-' + pathway.value.uuid + '-mapped');
    this.map.removeLayer('pathway-' + pathway.value.uuid + '-mapped-outline');
    this.map.removeSource('pathway-' + pathway.value.uuid + '-mapped');
  }

  mapToRoadsToggle(pathway: FormGroup): void {
    pathway.patchValue({
      mappedToRoads: !pathway.value.mappedToRoads
    });

    this.updatePathLayers(pathway);

    if (pathway.value.mappedToRoads) {
      this.loadMapMatching(pathway);
    } else {
      pathway.patchValue({
        mapMappedPath: null
      });

      this.updateMappedPathwaysLayerData(pathway);
    }
  }

  changePathwayMappingProfile(pathway: FormGroup, profile: 'walking' | 'cycling' | 'driving'): void {
    if (profile === pathway.value.mappedPathProfile) {
      return;
    }

    pathway.patchValue({
      mappedPathProfile: profile
    });

    if (pathway.value.mappedToRoads) {
      this.loadMapMatching(pathway);
    }
  }

  private updatePathLayers(pathway: FormGroup) {
    this.map.setPaintProperty('pathway-' + pathway.value.uuid, 'line-opacity', pathway.value.mappedToRoads ? 0.5 : 1);
    this.map.setPaintProperty('pathway-' + pathway.value.uuid, 'line-dasharray', pathway.value.mappedToRoads ? [2, 2] : [1]);
    this.map.setPaintProperty('pathway-' + pathway.value.uuid + '-outline', 'line-opacity', pathway.value.mappedToRoads ? 0.5 : 1);
    this.map.setPaintProperty('pathway-' + pathway.value.uuid + '-outline', 'line-dasharray', pathway.value.mappedToRoads ? [2, 2] : [1]);
  }

  private updateMappedPathwaysLayerData(pathway: FormGroup, mapMappedLineString?: GeoJSON.LineString) {
    (this.map.getSource('pathway-' + pathway.value.uuid + '-mapped') as mapboxgl.GeoJSONSource).setData({
      type: 'FeatureCollection',
      features: mapMappedLineString ? [{
        type: 'Feature',
        geometry: mapMappedLineString,
        properties: {}
      }] : []
    });
  }

  private loadMapMatching(pathway: FormGroup) {
    this.mapboxSpecificService.mapMatching(this.pathwaysEditEngines.get(pathway.value.uuid).orderedVertices.map((v) => v.coords), pathway.value.mappedPathProfile)
      .subscribe((value) => {
        if (!value.matchings?.length) {
          // pathway.patchValue({
          //   mappedToRoads: false
          // });
          this.snackBar.open(
            this.transloco.translate('canNotMapPathway'),
            this.transloco.translate('close'),
            {
              verticalPosition: 'bottom',
              duration: 5000,
              panelClass: 'error-snackbar'
            });

          const line = zoneToFeatureLineString(pathway.value.path).geometry as GeoJSON.LineString;

          pathway.patchValue({
            mapMappedPath: line
          });

          this.updateMappedPathwaysLayerData(pathway, line);
        } else {
          this.updateMappedPathwaysLayerData(pathway, value.matchings[0].geometry);
          pathway.patchValue({
            mapMappedPath: value.matchings[0].geometry
          })
        }
      });
  }

  getPathways(): FormArray {
    return this.obeliskForm.get('pathways') as FormArray;
  }

  getPathwaysAsFormGroups(): FormGroup[] {
    return this.getPathways().controls as FormGroup[];
  }

  private createPathwayFormGroup(pathway: Pathway): FormGroup {
    return this.fb.group({
      uuid: [pathway.uuid, [Validators.required]],
      name: [pathway.name, [Validators.required]],
      color: [pathway.color, [Validators.required]],
      vertices: [pathway.vertices, [Validators.required, Validators.minLength(2)]],
      path: [pathway.path, [Validators.required]],
      mappedToRoads: [pathway.mappedToRoads],
      mapMappedPath: [pathway.mapMappedPath],
      mappedPathProfile: [pathway.mappedPathProfile || 'walking']
    });
  }

  addComment(): void {
    this.stopEditCurrentComment();

    const comment = this.createCommentFormGroup({
      uuid: uuidv4(),
      coords: undefined,
      text: ''
    });

    const cE = this.initComment(comment);

    this.currentComment = comment;
    this.getComments().push(comment);
  }

  hideComments(): void {
    this.commentsShown = false;
    this.commentsEditEngines.forEach((value) => value.hidePopup());
  }

  showComments(): void {
    this.commentsShown = true;
    this.commentsEditEngines.forEach((value) => value.showPopup());
  }

  initComment(comment: FormGroup): CommentsEditEngine {
    const cE = new CommentsEditEngine(this.map, comment.value.uuid, comment.value.coords, comment.value.text, comment.value.imageUrl);

    cE.onStartEdit$().subscribe(() => {
      this.commentsEditEngines.forEach((value) => {
        if (value.getData().uuid !== cE.getData().uuid) {
          value.stopEditing();
        }
      });

      this.currentComment = comment;
    });

    this.commentsEditEngines.set(comment.value.uuid, cE);

    return cE;
  }

  getComments(): FormArray {
    return this.obeliskForm.get('comments') as FormArray;
  }

  getCommentsAsFormGroups(): FormGroup[] {
    return this.getComments().controls as FormGroup[];
  }

  removeComment(comment: FormGroup): void {
    this.stopEditCurrentComment();
    this.commentsEditEngines.get(comment.value.uuid).destroy();
    this.commentsEditEngines.delete(comment.value.uuid);

    this.getComments().removeAt(this.getComments().controls.indexOf(comment));
  }

  stopEditCurrentComment(): void {
    this.commentsEditEngines.get(this.currentComment?.value.uuid)?.stopEditing();

    if (this.currentComment) {
      this.currentComment = undefined;
    }
  }

  private createCommentFormGroup(comment: ObeliskComment): FormGroup {
    return this.fb.group({
      uuid: [comment.uuid, [Validators.required]],
      coords: [comment.coords, [Validators.required]],
      text: [comment.text, [Validators.required]],
      imageUrl: [comment.imageUrl]
    });
  }

  isValid(): boolean {
    return this.obeliskForm?.valid;
  }

  clickObeliskBtn(): void {
    this.editingObeliskZone = false;
    this.editingObeliskCenter = true;
  }

  choseZoneType(option: ChooserOption): void {
    if (option.value === this.obeliskForm.value.zoneType) {
      return;
    }

    this.obeliskForm.patchValue({
      zoneType: option.value
    });

    this.clearZone();

    if (option.value === 'circle') {
      this.polygonZoneEditEngine.stopEditing();
      this.changeRadius(this.obeliskForm.value.zoneRadius);
    } else if (option.value === 'polygon') {
      this.polygonZoneEditEngine.reveal();
      this.polygonZoneEditEngine.startEditing();
    }
  }

  removeObeliskMarker(): void {
    this.obeliskMarker.remove();
    this.obeliskMarker = null;
    this.obeliskForm.patchValue({
      location: null
    });
  }

  private obeliskFromCurrentForm(): Obelisk {
    const obelisk: Obelisk = {
      name: this.obeliskForm.value.name,
      subTitle: this.obeliskForm.value.subTitle,
      description: this.obeliskForm.value.description,
      svg: this.obeliskForm.value.svg,
      type: this.obeliskForm.value.type,
      category: this.obeliskForm.value.category?.value,
      subCategory: this.obeliskForm.value.subCategory?.value,
      importance: this.obeliskForm.value.importance.value,
      address: this.obeliskForm.value.address,
      location: this.obeliskForm.value.location,
      images: this.obeliskForm.value.images,
      config: {
        howToGet: this.obeliskForm.value.howToGet,
        website: this.obeliskForm.value.website,
        pathways: this.obeliskForm.value.pathways.map((pathway) => {
            return {
              uuid: pathway.uuid,
              name: pathway.name,
              color: pathway.color,
              vertices: pathway.vertices,
              path: pathway.path,
              mappedToRoads: pathway.mappedToRoads,
              mapMappedPath: pathway.mapMappedPath,
              mappedPathProfile: pathway.mappedPathProfile
            }
          }
        ),
        comments: this.obeliskForm.value.comments.map((comment) => {
          return {
            uuid: comment.uuid,
            coords: comment.coords,
            text: comment.text,
            imageUrl: comment.imageUrl
          }
        })
      }
    }

    if (this.obeliskForm.value.zoneType === 'circle') {
      obelisk.zone = {
        type: 'circle',
        radius: this.obeliskForm.value.zoneRadius
      }
    } else if (this.obeliskForm.value.zoneType === 'polygon') {
      obelisk.zone = {
        type: 'polygon',
        vertices: this.obeliskForm.value.polygonVertices
      }
    }

    return obelisk;
  }

  addImageClick(): void {
    if (this.getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES) {
      return;
    }

    const addPhotoInput = document.getElementById('add-photo-input');
    addPhotoInput?.click();
  }

  clickPasteImage(): void {
    if (this.getCurrentImages().length === this.MAX_NUMBER_OF_IMAGES) {
      return;
    }

    readImageFromClipboard()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value) {
          this.readImage(value);
        }
      });
  }

  getCurrentImages(): any[] {
    return this.obeliskForm.get('images')!.value;
  }

  removeImgByUuid(uuid: string): void {
    const images = this.getCurrentImages();
    let imagesNew = images.filter((i: ObeliskImage) => i.uuid !== uuid);

    this.obeliskForm.get('images')?.setValue(imagesNew);
  }

  private readImage(file: any): void {
    const reader = new FileReader();
    const existingImages = this.obeliskForm.get('images')?.value || [];
    const onLoadHandler = () => {
      this.obeliskForm.get('images')?.setValue([...existingImages, {
        uuid: uuidv4(),
        order: existingImages.length,
        uri: reader.result
      } as ObeliskImage]);

      reader.removeEventListener('load', onLoadHandler);
    }

    reader.addEventListener('load', onLoadHandler);

    reader.readAsDataURL(file);
  }

  openEditImageDescriptionDialog(image: ObeliskImage): void {
    this.editingImageDescriptionId = image.uuid;
    this.editingImageDescription = image.description || '';
  }

  closeEditImageDescriptionDialog(): void {
    this.editingImageDescriptionId = null;
    this.editingImageDescription = '';
  }

  submitEditImageDescription(): void {
    const images = this.getCurrentImages();
    const image = images.find((i: ObeliskImage) => i.uuid === this.editingImageDescriptionId);
    image.description = this.editingImageDescription;

    this.obeliskForm.get('images')?.setValue(images);
    this.closeEditImageDescriptionDialog();
  }

  private drawObeliskMarker(coords: GeoCoords): mapboxgl.Marker {
    this.obeliskForm.patchValue({
      location: coords
    });

    return drawMarkerOnMap(coords, this.map, '../../../../assets/map-icons/active-marker-1.svg');
  }

  private changeRadius(value: number): void {
    this.obeliskForm.patchValue({
      zoneRadius: value
    });

    this.onRadiusChange(value);
  }

  private onRadiusChange(value: number): void {
    if (!this.map.getSource('start-zones')) {
      return;
    }

    const center = this.obeliskForm.value.location;

    const circle = turf.circle([center.lng, center.lat], value, {steps: 60, units: 'meters'});

    (this.map.getSource('start-zones') as mapboxgl.GeoJSONSource).setData(circle);
    (this.map.getSource('start-zone-outline') as mapboxgl.GeoJSONSource).setData(circle);
  }

  private addPolygonVertex(coords: GeoCoords): void {
    this.polygonZoneEditEngine.clickOnMap(coords);
  }

  private clearZone(): void {
    (this.map.getSource('start-zones') as mapboxgl.GeoJSONSource).setData(turf.featureCollection([]));
    (this.map.getSource('start-zone-outline') as mapboxgl.GeoJSONSource).setData(turf.featureCollection([]));
    this.polygonZoneEditEngine.hide();
  }

  private addPathwayMapLayers(pathway: FormGroup) {
    const id = 'pathway-' + pathway.value.uuid;

    this.map.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    this.map.addLayer({
      id: id + '-outline',
      type: 'line',
      source: id,
      layout: {},
      paint: {
        'line-color': '#efefef',
        'line-width': 4,
        'line-opacity': pathway.value.mappedToRoads ? 0.5 : 1,
        'line-dasharray': pathway.value.mappedToRoads ? [2, 2] : [1]
      }
    });

    this.map.addLayer({
      id,
      type: 'line',
      source: id,
      layout: {},
      paint: {
        'line-color': pathway.value.color,
        'line-width': 2,
        'line-opacity': pathway.value.mappedToRoads ? 0.5 : 1,
        'line-dasharray': pathway.value.mappedToRoads ? [2, 2] : [1]
      }
    });

    this.map.addSource(id + '-mapped', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    });

    this.map.addLayer({
      id: id + '-mapped-outline',
      type: 'line',
      source: id + '-mapped',
      layout: {},
      paint: {
        'line-color': '#efefef',
        'line-width': 4,
      }
    });

    this.map.addLayer({
      id: id + '-mapped',
      type: 'line',
      source: id + '-mapped',
      layout: {},
      paint: {
        'line-color': pathway.value.color,
        'line-width': 2,
      }
    });

    this.pathwaysStrokeColors.get(pathway.value.uuid).subscribe((color) => {
      this.map.setPaintProperty(id, 'line-color', color);
      this.map.setPaintProperty(id + '-mapped', 'line-color', color);
    })
  }

  // TODO move somewhere to a common place
  goToMyLocation(): void {
    if (this.userGeo) {
      this.map.setCenter([this.userGeo.lng, this.userGeo.lat]);
    } else {
      navigator.geolocation.getCurrentPosition((value) => {
        this.userGeo = {lng: value.coords.longitude, lat: value.coords.latitude};
        this.map.setCenter([this.userGeo.lng, this.userGeo.lat]);
      });
    }
  }

  // TODO move somewhere to a common place
  toggleMagStyle(): void {
    if (this.map.getStyle().name === 'Mapbox Outdoors') {
      this.map.setStyle('mapbox://styles/mapbox/satellite-v9');
    } else {
      this.map.setStyle('mapbox://styles/mapbox/outdoors-v12');
    }

    this.map.once('styledata', () => {
      addLayersToMap(this.map);

      this.getPathwaysAsFormGroups().forEach((pathway) => {
        this.addPathwayMapLayers(pathway);
      });

      this.map.once('styledata', () => {
        this.map.triggerRepaint();
        this.polygonZoneEditEngine.externalRedraw();

        this.getPathwaysAsFormGroups().forEach((pathway) => {
          this.pathwaysEditEngines.get(pathway.value.uuid).externalRedraw();
          if (pathway.value.mappedToRoads) {
            this.updateMappedPathwaysLayerData(pathway, pathway.value.mapMappedPath);
          }
        });
      });
    });
  }

  private storeLastLocation(coords: GeoCoords): void {
    localStorage.setItem('lastLocation', JSON.stringify(coords));
  }

  private getLastLocation(): GeoCoords | null {
    const ll = localStorage.getItem('lastLocation');
    return ll ? JSON.parse(ll) : null;
  }

  ngOnDestroy(): void {
    this.polygonZoneEditEngine.destroy();

    this.pathwaysStrokeColors.forEach((value) => {
      value.complete();
    });

    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly metersToPretty = metersToPretty;
}
