import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {QuestEditorComponent} from './pages/quest-editor/quest-editor.component';
import {QuestListComponent} from './pages/quest-list/quest-list.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {QuestSinglePageComponent} from './pages/quest-single-page/quest-single-page.component';
import {
  QuestPublishRequestsAdminComponent
} from './pages/quest-publish-requests-admin/quest-publish-requests-admin.component';
import {SuperuserGuardService} from './guards/superuser-guard.service';
import {QuestAdminSinglePageComponent} from './pages/quest-admin-single-page/quest-admin-single-page.component';
import {ForbiddenTooManyQuestsComponent} from './pages/forbidden-too-many-quests/forbidden-too-many-quests.component';
import {QuestLimitPerDayGuardService} from './guards/quest-limit-per-day-guard.service';
import {QuestPermissionsGuardService} from './guards/quest-permissions-guard.service';
import {QuestsChainsListComponent} from './pages/quests-chains-list/quests-chains-list.component';
import {ExplorationComponent} from './pages/exploration/exploration.component';
import {ObelisksEditorComponent} from './pages/obelisks-editor/obelisks-editor.component';
import {PrivacyPolicyComponent} from './common-components/privacy-policy/privacy-policy.component';
import {TermsOfUseComponent} from './common-components/terms-of-use/terms-of-use.component';
import {ProfileComponent} from './common-components/profile/profile.component';
import {QuestAcceptShareComponent} from './pages/quest-accept-share/quest-accept-share.component';
import {OdmListComponent} from './pages/odm-list/odm-list.component';
import {waitForTranslationToLoadResolver} from 'quest-atlas-angular-components';
import {EmbeddedMapComponent} from './pages/embedded-map/embedded-map.component';
import {ProxyToMapRedirectComponent} from './pages/proxy-to-map-redirect/proxy-to-map-redirect.component';
import {translationLoaded$} from './transloco-loader';

export const MY_QUESTS_PATH = 'my-quests';
export const MY_CHAINS_PATH = 'my-chains';
export const EXPLORATION_PATH = 'exploration';
export const SHARED_QUESTS_PATH = 'shared-quests';
export const MESSAGES_PATH = 'messages';
export const MARKETPLACE_PATH = 'marketplace';
export const ADMIN_QUEST_PUBLISH_REQUESTS_PATH = 'quest-publish-requests';
export const FORBIDDEN_PATH = 'forbidden';
export const ACCEPT_SHARE_PATH = 'accept-share';

const routes: Routes = [
  {
    path: 'quest-editor/:id',
    component: QuestEditorComponent,
    canActivate: [QuestPermissionsGuardService, QuestLimitPerDayGuardService],
    resolve: {translationLoaded: waitForTranslationToLoadResolver(translationLoaded$)}
  },
  {
    path: 'quest-editor/adaptive/:id',
    component: QuestEditorComponent,
    canActivate: [QuestPermissionsGuardService, QuestLimitPerDayGuardService],
    resolve: {translationLoaded: waitForTranslationToLoadResolver(translationLoaded$)},
    data: {adaptive: true}
  },
  {
    path: 'obelisks-editor/:id',
    component: ObelisksEditorComponent,
    canActivate: [SuperuserGuardService],
    resolve: {translationLoaded: waitForTranslationToLoadResolver(translationLoaded$)}
  },
  {
    path: 'password-reset/confirm/:userid/:token',
    redirectTo: 'auth/password-reset/confirm/:userid/:token',
  },
  {
    path: 'show-quest/:id',
    component: ProxyToMapRedirectComponent
  },
  {
    path: 'auth',
    resolve: {translationLoaded: waitForTranslationToLoadResolver(translationLoaded$)},
    loadChildren: () => import(`./auth/auth.module`).then((module) => module.AuthModule)
  },
  {
    path: 'quests-chains',
    loadChildren: () => import(`./pages/quests-chains/quests-chains.module`).then((module) => module.QuestsChainsModule)
  },
  {
    path: `${FORBIDDEN_PATH}/too-many-quests`,
    component: ForbiddenTooManyQuestsComponent,
    canActivate: [QuestLimitPerDayGuardService]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsOfUseComponent
  },
  {
    path: 'main-map',
    component: EmbeddedMapComponent
  },
  {
    path: 'show-place/:id',
    component: EmbeddedMapComponent
  },
  {
    path: 'view-record',
    component: EmbeddedMapComponent
  },
  {
    path: 'th/:id',
    component: EmbeddedMapComponent
  },
  {
    path: 'qh/:id',
    component: EmbeddedMapComponent
  },
  {
    path: '',
    component: HomeComponent,
    resolve: {translationLoaded: waitForTranslationToLoadResolver(translationLoaded$)},
    children: [
      {path: MY_QUESTS_PATH, component: QuestListComponent},
      {
        path: `${MY_QUESTS_PATH}/single/:id`,
        component: QuestSinglePageComponent,
        canActivate: [QuestPermissionsGuardService]
      },
      {path: SHARED_QUESTS_PATH, component: QuestListComponent},
      {
        path: `${SHARED_QUESTS_PATH}/single/:id`,
        component: QuestSinglePageComponent,
        canActivate: [QuestPermissionsGuardService]
      },
      {path: MY_CHAINS_PATH, component: QuestsChainsListComponent, canActivate: [SuperuserGuardService]},
      {path: EXPLORATION_PATH, component: ExplorationComponent, canActivate: [SuperuserGuardService]},
      {path: MESSAGES_PATH, component: OdmListComponent},
      {path: MARKETPLACE_PATH, component: NotFoundComponent},
      {
        path: ADMIN_QUEST_PUBLISH_REQUESTS_PATH,
        component: QuestPublishRequestsAdminComponent,
        canActivate: [SuperuserGuardService]
      },
      {
        path: `${ADMIN_QUEST_PUBLISH_REQUESTS_PATH}/single/:id`,
        component: QuestAdminSinglePageComponent,
        canActivate: [SuperuserGuardService]
      },
      {path: `${ACCEPT_SHARE_PATH}/:shareCode`, component: QuestAcceptShareComponent},
      {path: '**', redirectTo: MY_QUESTS_PATH}
    ]
  },
  {path: 'profile/delete', component: ProfileComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'}), CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
