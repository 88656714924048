<div class="dropdown-input" [ngClass]="{'focused': active}">
  <div class="text-container" (click)="clickOnComponent()">
    <div class="title-container" [ngClass]="{'minified': active || selectedValue}">{{title()}}</div>
    @if (active || selectedValue) {
      <div class="selected-value-container">
        {{selectedValue ? selectedValue.title : ''}}
      </div>
    }
  </div>
  <img class="dropdown-arrow" src="assets/project-icons/dropdown-arrow.svg" [ngClass]="{'rotated-icon': active}" alt=""
    (click)="clickOnComponent()">

    @if (active) {
      <div class="dropdown">
        <ul>
          @if (optional) {
            <li class="dropdown-option clear" (click)="clearValue()">
              <div class="semibold-14 secondary-text">{{'toClear' | transloco}}</div>
            </li>
          }
          @for (option of options(); track option) {
            <li class="dropdown-option" [ngClass]="{'disabled': option.disabled}" (click)="chooseValue(option)">
              @if (option.imgName) {
                <div class="option-img usual-flex-center">
                  <svg class="regular-img-size">
                    <use [attr.xlink:href]="'assets/project-icons/' + option.imgName + '.svg#c'"></use>
                  </svg>
                </div>
              } @else if (option.imgSvg) {
                <div class="raw-svg-container usual-flex-center" [innerHTML]="option.imgSvg"></div>
              } @else if (option.imgUrl) {
                <img [src]="option.imgUrl" class="margin-right-12" style="width: 24px; height: 24px;">
              }
              @if (option.description) {
                <div>
                  <div class="title">{{option.title}}</div>
                  <div class="description">{{option.description}}</div>
                </div>
              } @else {
                <div>{{option.title}}</div>
              }
            </li>
          }
        </ul>
      </div>
    }
  </div>
