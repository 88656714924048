import {Component, computed, input, output, signal} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow, MatRowDef, MatTable, MatTableDataSource
} from '@angular/material/table';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatSort, MatSortHeader, Sort} from '@angular/material/sort';
import {TranslocoDirective} from '@ngneat/transloco';
import {compare, Obelisk} from 'quest-atlas-angular-components';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-obelisks-table-list',
  standalone: true,
  imports: [
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatRow,
    MatRowDef,
    MatSort,
    MatSortHeader,
    MatTable,
    TranslocoDirective,
    MatHeaderCellDef,
    MatMenuTrigger,
    NgClass
  ],
  templateUrl: './obelisks-table-list.component.html',
  styleUrl: './obelisks-table-list.component.scss'
})
export class ObelisksTableListComponent {
  displayedColumns: string[] = ['name', 'address', 'updatedAt', 'createdAt', 'category', 'subCategory', 'menu'];

  obelisks = input.required<Obelisk[]>();
  currentSort = signal<Sort>(null);
  sortedObelisks = computed(() => {
    const currentObelisks = this.obelisks();

    if (!this.currentSort()) {
      return currentObelisks;
    }

    if (!this.currentSort().active || this.currentSort().direction === '') {
      return currentObelisks;
    }

    return currentObelisks.sort((a, b) => {
      if (this.displayedColumns.includes(this.currentSort().active)) {
        return compare(a[this.currentSort().active], b[this.currentSort().active], this.currentSort().direction === 'asc');
      }
      return 0;
    })
  });
  dataSource = computed<MatTableDataSource<Obelisk>>(() => new MatTableDataSource<Obelisk>(this.sortedObelisks()));

  select = output<Obelisk>();
  edit = output<Obelisk>();
  delete = output<Obelisk>();
  approve = output<Obelisk>();
  reject = output<Obelisk>();


  sortData(sort: Sort) {
    this.currentSort.set(sort);
  }

  obeliskImageUri(obelisk: Obelisk): string {
    if (!obelisk.images?.[0]?.uri) {
      return '';
    }

    if (obelisk.createdAt < new Date('2024-08-31')) {
      return obelisk.images[0].uri
    }

    const split = obelisk.images[0].uri.split('/');

    split[split.length - 1] = 'thumbnails/' + split[split.length - 1];

    return split.join('/');
  }
}
