<table mat-table [dataSource]="dataSource()" matSort class="mat-elevation-z0" (matSortChange)="sortData($event)" *transloco="let t">
  <ng-container matColumnDef="name">
    <th mat-header-cell mat-sort-header="name" sortActionDescription="Sort by name" *matHeaderCellDef>{{t('name')}}</th>
    <td mat-cell *matCellDef="let obelisk">
      <div class="name-col-container">
        <div class="obelisk-image-container">
          @if (obelisk?.images.length) {
            <img [src]="obeliskImageUri(obelisk)" alt="" />
          }
        </div>
        <div>{{ obelisk.name }}</div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="address">
    <th mat-header-cell *matHeaderCellDef>{{t('address')}}</th>
    <td mat-cell *matCellDef="let obelisk">{{ obelisk.address }}</td>
  </ng-container>

  <ng-container matColumnDef="updatedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="updatedAt" sortActionDescription="Sort by last modified">{{t('lastModified')}}</th>
    <td mat-cell *matCellDef="let obelisk">{{ obelisk.updatedAgo }}</td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt" sortActionDescription="Sort by created">{{t('created')}}</th>
    <td mat-cell *matCellDef="let obelisk">{{ obelisk.createdAgo }}</td>
  </ng-container>

  <ng-container matColumnDef="category">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="category" sortActionDescription="Sort by category">{{t('category')}}</th>
    <td mat-cell *matCellDef="let obelisk">{{ t(obelisk.category) }}</td>
  </ng-container>

  <ng-container matColumnDef="subCategory">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="subCategory" sortActionDescription="Sort by subcategory">{{t('subcategory')}}</th>
    <td mat-cell *matCellDef="let obelisk">{{ t(obelisk.subCategory) }}</td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" sortActionDescription="Sort by status">{{t('state')}}</th>
    <td mat-cell *matCellDef="let obelisk">
      {{ obelisk.status }}
    </td>
  </ng-container>

  <ng-container matColumnDef="menu">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let obelisk" class="menu-table-data">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        @if (obelisk.approved) {
          <button mat-menu-item (click)="reject.emit(obelisk)">
            <span>{{t('reject')}}</span>
          </button>
        } @else {
          <button mat-menu-item (click)="approve.emit(obelisk)">
            <span>{{t('approve')}}</span>
          </button>
        }
        <button mat-menu-item (click)="edit.emit(obelisk)">
          <span>{{t('toEdit')}}</span>
        </button>
        <button mat-menu-item (click)="delete.emit(obelisk)">
          <span>{{t('delete')}}</span>
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"
      (click)="select.emit(row)"
      [ngClass]="{'non-approved-row': !row.approved}"
  ></tr>
</table>
